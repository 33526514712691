import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxMaskModule } from 'ngx-mask';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER } from 'ngx-ui-loader';

import { BannerComponent } from './components/banner/banner.component';
import { FormControlsModule } from './components/forms/form-controls.module';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { GrowlerComponent } from './components/growler/growler.component';
import { MyCashBalanceComponent } from './components/mycash-balance/mycash-balance.component';
import { PasswordRulesComponent } from './components/password-rules/password-rules.component';
import { SectionLoaderComponent } from './components/section-loader/section-loader.component';
import { ServiceIconsComponent } from './components/service-icons/service-icons.component';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { TransactionHistoryComponent } from './components/transaction-history/transaction-history.component';
import { TransactionSummaryComponent } from './components/transaction-summary/transaction-summary.component';
import { MaterialSharedModule } from './material-shared.module';
import { PipesModule } from './pipes/pipes.module';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ConfirmService } from './components/confirm/confirm.service';
import {TransactionListComponent} from '@app/shared/components/transaction-list/transaction-list.component';
import {ScheduledPaymentsNotificationComponent} from '@app/shared/components/scheduled-payments-notifications/scheduled-payments-notification.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { RouteLoadingComponent } from './components/route-loading/route-loading.component';
import { ContactUsModalComponent } from './components/contact-us-modal/contact-us-modal.component';

@NgModule({
  declarations: [
    BannerComponent,
    DateRangeComponent,
    GrowlerComponent,
    MyCashBalanceComponent,
    PasswordRulesComponent,
    ServiceIconsComponent,
    SectionLoaderComponent,
    TransactionHistoryComponent,
    TransactionSummaryComponent,
    SkeletonLoaderComponent,
    ConfirmComponent,
    TransactionListComponent,
    ScheduledPaymentsNotificationComponent,
    TooltipComponent,
    RouteLoadingComponent,
    ContactUsModalComponent,
  ],
  exports: [
    BannerComponent,
    DateRangeComponent,
    GrowlerComponent,
    MyCashBalanceComponent,
    PasswordRulesComponent,
    ServiceIconsComponent,
    SectionLoaderComponent,
    TransactionHistoryComponent,
    TransactionSummaryComponent,
    SkeletonLoaderComponent,
    MaterialSharedModule,
    ConfirmComponent,
    TransactionListComponent,
    ScheduledPaymentsNotificationComponent,
    TooltipComponent,
    RouteLoadingComponent,
    ContactUsModalComponent,
  ],
  imports: [
    A11yModule,
    BsDropdownModule,
    CommonModule,
    FormControlsModule,
    NgxMaskModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
    TooltipModule,
    MaterialSharedModule,
  ],
  providers: [
    ConfirmService,
  ],
})
export class SharedModule { }
