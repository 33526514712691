import { Component, ViewChild } from '@angular/core';
import { ContentfulContactUsModalData } from '@app/shared/models/pux';
import { BrandService } from '@app/shared/services/brand.service';
import { ContentfulService } from '@app/shared/services/contentful.service';
import { ToastrService } from 'ngx-toastr';
import { ModalComponent } from 'src/app/shared/components/forms/modal/modal.component';

@Component({
  selector: 'app-contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
  styleUrls: ['./contact-us-modal.component.scss'],
})
export class ContactUsModalComponent {
  @ViewChild(ModalComponent, { static: true }) public modal: ModalComponent;
  public items: ContentfulContactUsModalData[] = [];

  public constructor(
    private brandService: BrandService,
    private contentfulService: ContentfulService,
    private toastr: ToastrService,
  ) { }

  public async open(): Promise<void> {
    this.contentfulService.getContactUsContent(this.brandService.getBrand())
      .then(
        (res) => {
          this.items = res.items.length
            ? res.items.sort((a, b) => a.fields.index - b.fields.index).map((i) => i.fields)
            : this.populateGeneralNumber();
        },
        (err) => {
          this.items = this.populateGeneralNumber();
          this.toastr.error('Something went wrong fetching contact phone numbers.');
        },
      );
    this.modal.open();
  }

  private populateGeneralNumber(): ContentfulContactUsModalData[] {
    return [{ title: 'N/A', specifier: 'For Customer Care, call:', phone: this.brandService.getBrandResources().phoneNumber, index: 1 }];
  }
}
