import { Injectable } from '@angular/core';
import { createClient, EntryCollection } from 'contentful';
import 'regenerator-runtime/runtime';
import { environment } from '../../../environments/environment';
import { ContentfulContactUsModalData, ContentfulContentType } from '../models/pux/model';

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  public client = createClient({
    space: environment.contentful.space,
    accessToken: environment.contentful.accessToken,
    environment: environment.contentful.environment,
  });

  /**
   * Get the additional content for the login screen Contact Us modal.
   * @returns The collection of additional elements to display.
   */
  public async getContactUsContent(brandId: string): Promise<EntryCollection<ContentfulContactUsModalData>> {
    return this.client.getEntries({
      content_type: ContentfulContentType[`PuxContactUsModalContent${brandId}`],
      select: 'fields',
    });
  }
}
