<div class="login-container text-left">
  <div class="d-flex w-100 flex-wrap">
    <div class="login-form d-flex col-lg-6 text-center">
      <div class="tasc-logo upper-left">
        <img [alt]="brand.companyName" src="assets/images/{{brand.logoFileName}}">
      </div>
      <div>
        <h1 class="login-federated-title">Signing in...</h1>
        <p class="login-federated-subtext">This may take a minute.</p>
      </div>
      <div class="loader-yellow loader-federated"></div>
      <div *ngIf="timer$ | async as seconds" aria-live="assertive">
        <span *ngIf="seconds > 1">Signing in This may take a minute</span>
      </div>
      <footer class="terms-footer text-left">
        <div class="row other-information">
          <div class="col-lg-12">
            <p>By continuing you agree to the
              <button tabindex="0" data-cy="terms-of-use" type="button" class="no-border-btn btn-link font-regular" (click)="openTermsOfUse()">Terms of Use</button>
              and
              <button tabindex="0" type="button" class="no-border-btn btn-link font-regular" (click)="openPrivacyPolicy()">Privacy Notice</button>
            </p>
          </div>
        </div>
        <div class="row other-information">
          <div class="col-lg-6 order-lg-last">
            <p data-cy="phone-number" class="pt-1">
              Trouble signing in?
              <button tabindex="0" data-cy="contact-us" type="button" class="no-border-btn btn-link font-regular" (click)="openContactUs()">Contact Us</button>
            </p>
          </div>
          <div class="col-lg-6">
            <p><i class="icon-lock mr-3" aria-hidden="true"></i>Your information is secure</p>
          </div>
        </div>
      </footer>
      <app-terms-of-use-modal></app-terms-of-use-modal>
    </div>
    <div class="login-bg col-lg-6 position-relative {{brand.brandCssClass}}">
      <div class="login-bg-wrapper">
        <div class="login-bg-text">WELCOME <br>TO {{brand.companyName}}</div>
        <span class="separator"></span>
      </div>
      <footer class="signup-footer text-right">
        <p class="mb-1 font-12">&copy; Copyright {{currentYear}} {{brand.companyNameCopyright}}. All rights reserved.</p>
        <p class="font-12 text-center">V{{ version }}</p>
      </footer>
    </div>
  </div>
</div>
<app-contact-us-modal></app-contact-us-modal>
