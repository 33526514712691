export const environment = {
  contentful: {
    space: 'fbth0d1d3tpo',
    accessToken: 'b0b2fa9aba9cfd32562a08d1eb49212ff7ffe2db53af5664307f5d6a7b7231c4',
    environment: 'master',
  },
  name: 'p1',
  timezone: 'America/Chicago',
  production: true,

  logging: {
    apiKey: 'So4wpCOxWNPh6LPfZB6Qig',
    debugMode: false,
  },

  services: {
    producerId: '331939ff-65f0-49c8-89ad-033cb550f419',
    idp: {
      endpoint: 'https://tlg5tv99le.execute-api.us-east-1.amazonaws.com/dev/auth/v1',
    },
    core: {
      endpoint: 'https://qmsbf5c548.execute-api.us-east-1.amazonaws.com/dev',
    },
    communication: {
      endpoint: 'https://p1-api.tascadmin.com/communication/v1',
    },
    configuration: {
      endpoint: 'https://p1-api.tascadmin.com/configuration/v1',
    },
    paymentAccount: {
        endpoint: 'https://p1-api.tascadmin.com/paymentAccount/v1',
    },
    profile: {
      endpoint: 'https://p1-api.tascadmin.com/profile/v1',
    },
    profileConfiguration: {
      endpoint: 'https://p1-api.tascadmin.com/profileconfiguration/v1',
    },
    account: {
      endpoint: 'https://p1-api.tascadmin.com/account/v1',
    },
    request: {
      endpoint: 'https://p1-api.tascadmin.com/request/v1',
    },
    lookup: {
      endpoint: 'https://p1-api.tascadmin.com/lookup/v1',
    },
    card: {
      endpoint: 'https://p1-api.tascadmin.com/card/v1',
    },
    file: {
      endpoint: 'https://p1-api.tascadmin.com/file/v1',
    },
    security: {
      endpoint: 'https://p1-api.tascadmin.com/security/v1',
    },
    support: {
      endpoint: 'https://p1-api.tascadmin.com/support/v1',
    },
    dashboard: {
      endpoint: 'https://p1-api.tascadmin.com/dashboard/v1',
    },
  },
  aws: {
    userPoolId: 'us-east-1_joPxz2hWe',
    clientId: '1s4jtgb0et8qd6383eu9petvet',
    crossroadsClientId: '216pdo8vd5658362t6v2v22c5p',
    cognitoURL: 'https://prod-1-tasc-bam-admin-ui-new.auth.us-east-1.amazoncognito.com',
  },
  finix: {
    applicationId: 'TODO',
    env: 'live',
  },
  awsRegion: 'us-east-1',
  phoneContryCode: '+1',
  publicRoutingNumberEndPoint: 'https://www.routingnumbers.info/api/data.json?rn=',
  braintreeAuth: 'production_9q5jfsnv_csbsry7d5fd4tp3s',
};
