import { KeyData } from '@models/card/model';
import { TradeActivity } from '@models/dashboard/model';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { Attachment } from 'src/app/shared/models/uba/file/model';
import { Dependent, Individual } from 'src/app/shared/models/uba/profile/model';
import {
  CcxSubstantiation,
  PaymentStatusType,
  RequestCardPaymentState,
  RequestManualPaymentState,
  RequestMethodType,
  RequestSourceType,
  RequestState,
} from 'src/app/shared/models/uba/request/model';

import {
  AccountType,
  BenefitAccountBalance,
  BenefitAccountPostingSummary,
  EntryPosting,
  EntryState,
  EntryType,
  OperationalBalanceInfo,
  PaymentSourceType,
  PostingState,
  TransactionType,
} from '../uba/account/model';
import { CarryOverLabel, FundingSourceType } from '../uba/configuration/model';
import { DestinationType, FundsTransferCriterion, SourceType } from '../uba/profileConfiguration/model';
import {
  ContentType,
  DateRangeType,
  PageFilter,
  TradeDescription,
  TradeStatus,
  TransactionActivityEndpointSource,
  TransactionActivityType,
  TransactionCategory,
  TransactionState,
  TransactionStateCategory,
  TransactionTypeLabel,
  UniqueIdType,
} from './enum';

export interface TransactionViewModel {
  uniqueId: string; // Either request ID, request payment ID, or posting ID
  uniqueIdType: UniqueIdType;
  requestId: string;
  title: string;
  transactionType: TransactionTypeLabel;
  transactionDate: string;
  transactionDateTooltip: string;
  status: TransactionState;
  amount: number;
  accountName: string;
  verificationRequired: boolean;
}

export interface TransactionHistoryViewModel {
  pendingTransactions: TransactionViewModel[];
  appliedTransactions: TransactionViewModel[];
  postedTransactions: TransactionViewModel[];
}

export interface TransactionDetailViewModel extends TransactionViewModel {
  description: string;
  scheduledDate: string;
  serviceDate: string;
  submittedDate: string;
  incurredBy: string;
  externalClaimId: string;
  serviceType: string;
  expenseType: string;
  attachments$?: Observable<Attachment[]>;
  isManualRequest: boolean;
}

export interface ViewModelSearchCriteria {
  pageFilter: PageFilter;
  dateRangeType?: DateRangeType;
  endDate?: string;
  startDate?: string;
  take?: number;
}

export interface TransactionViewModelSearchCriteria extends ViewModelSearchCriteria {
  transactionStateCategories?: TransactionStateCategory[];
  benefitPlanIds?: string[];
}

export interface TradeViewModelSearchCriteria extends ViewModelSearchCriteria {
  pending?: boolean;
  planNames?: string[];
}

/**
 * A view model that can be used for referencing funding sources in the UI. For example, a list of benefit plans, bank accounts,
 * and MyCash for use in a radio button list of sources for a Giving Account donation.
 */
export interface FundingSourceViewModel {
  fundingId: string; // Benefit plan ID, bank account payment source ID, or MyCash ID
  fundingType: SourceType;
  displayName: string;
  balance: number;
}

export interface TransferDestinationViewModel extends FundingSourceViewModel {
  destinationType: DestinationType;
}

export interface SearchResults<T> {
  data: T;
  hasMore: boolean;
}

export interface FileAttachment {
  // Either attachment *or* file must be specified, but not both or none
  attachment?: Attachment; // Represents a file that exists in the Attachment table (i.e. one that has already been uploaded)
  file?: File; // Represents a file the user has selected for upload
}

export interface FileAttachmentModel extends FileAttachment {
  fileName?: string;
  fileUrl?: string;
  contentType?: ContentType;
}

export interface TransactionPageViewModel {
  page: PageFilter;
  title: string;
  noTransactionLabel: string;
  enableFilter: boolean;
}

export interface Pagination {
  take?: number;
  skip?: number;
}

export interface SearchQuery extends Pagination {
  orderBy?: string;
  orderDirection?: string;
  enroller?: string;
}

export interface ScheduleQuery extends SearchQuery {
  effectiveDate: string;
}

export interface TransactionActivitySearchQuery extends SearchQuery {
  transactionType?: TransactionActivityEndpointSource;
  transactionActivityType?: TransactionActivityType;
}

export interface OperationalBalanceInfoSearchQuery extends SearchQuery {
  benefitAccountId: string;
}

export interface BenefitAccountLimitedBalanceQuery extends Pagination {
  dateTime: string;
}

/**
 * A container for files the user wants to link to a request or transaction.
 */
export interface FileUploadContainer {
  filesToUploadAndLink: File[]; // Files that are pending upload from browser
  filesToLink: Attachment[]; // Files from bills & receipts
  claimsToLink: CcxSubstantiation[];
}

// Temporary custom interface until we can refactor the UFT code
export interface FundsTransferCriterionViewModel extends FundsTransferCriterion {
  bankName: string;
}

export interface ResetPasswordData {
  verifycode: string;
  newpassword: string;
}

export interface BenefitBalance {
  id?: string;
  account?: BenefitAccountBalance;
  balanceSummary?: BenefitAccountPostingSummary;
}

export interface CharityEntryPostingViewModel extends EntryPosting {
  charityName?: string;
}

export interface BenefitAccountPostingSummaryViewModel extends BenefitAccountPostingSummary {
  id?: string;
}

export interface DependentBenefitAccessViewModel {
  dependentId: string;
  fullName: string;
  eligibilityStartDate: string;
  eligibilityEndDate: string;
}

export interface DependentViewModel {
  dependent: Dependent;
  benefitAccounts: BenefitAccountBalance[];
  numberOfCards: number;
  fullName: string;
  relationship: string;
}

export interface ProfileTieredPlanViewModel {
  fullName: string;
  deductibleForProfile: number;
  deductibleForGroup: number;
  hasPlanDeductible: boolean;
  planDeductibleForGroup: number;
  planDeductibleForPerson: number;
  payoutForProfile: number;
  payoutForGroup: number;
}

/**
 * An item returned from the transactionActivity/search endpoint. Similar to the TransactionActivity interface defined
 * in the UBA dashboard micro-service except this one specifies enums instead of strings for several properties.
 */
export interface TransactionActivity {
  individualId: string;
  benefitAccountId?: string;
  soaAccountId?: string;
  planId?: string;
  planName?: string;
  postingId?: string;
  postingType?: TransactionType;
  postingAccountId?: string;
  postingAmount?: number;
  postingCurrentState?: PostingState;
  accountType?: AccountType;
  entryId?: string;
  entryParentId?: string;
  entryPayeeId?: string;
  entryPaymentSourceId?: string;
  entrySoaData?: string;
  entryLinkId?: string;
  entryType?: EntryType;
  entryFeeType?: string;
  entryFundingSource?: FundingSourceType;
  entryCurrentState?: EntryState;
  entryScheduledDate?: string;
  entryStartDateTime?: string;
  entryEndDateTime?: string;
  entryTransactionDateTime?: string;
  entryOriginalPaymentSourceType?: PaymentSourceType;
  entryAmount?: number;
  transactionType?: TransactionCategory;
  transactionState?: RequestCardPaymentState | RequestManualPaymentState | 'Posted';
  requestId?: string;
  requestCurrentState?: RequestState;
  requestDescription?: string;
  requestRequestedAmount?: number;
  requestPaymentId?: string;
  requestPaymentPaymentAmount?: number;
  requestPaymentRequestedAmount?: number;
  requestPaymentPaymentDateTime?: string;
  requestPaymentPaymentStatus?: PaymentStatusType;
  requestType?: string;
  requestMethod?: RequestMethodType;
  requestServiceDate?: string;
  requestSourceId?: RequestSourceType;
  requestExternalClaimId?: string;
  requestSubmissionDateTime?: string;
  requestServiceProvider?: string;
  requestExpenseSourceName?: string;
  requestIncurredByName?: string;
  fundsTransferId?: string;
  fundsTransferAmount?: number;
  fundsTransferSourceId?: string;
  fundsTransferSourceType?: SourceType;
  fundsTransferDestinationId?: string;
  fundsTransferDestinationType?: DestinationType;
  fundsTransferNextEntryDate?: string;
  fundsTransferType?: string;
}

export interface TransactionActivityModel extends TransactionActivity {
  uniqueId: string;
  uniqueIdType: UniqueIdType;
  dataSource: TransactionActivityEndpointSource;
  transactionStateCategory: TransactionStateCategory;
  verificationRequired: boolean;
  sourcePaymentName: string;
  sourcePaymentSourceType: PaymentSourceType;
  destinationPaymentName: string;
  destinationPaymentSourceIsGivingAccount: boolean;
  destinationPaymentSourceType: PaymentSourceType;
  carryoverLabel: CarryOverLabel;
  isGivingAccount: boolean;
  linkedEntryTypes?: EntryType[];
}

export interface TradeActivityModel extends TradeActivity {
  uniqueId: string;
  requestedAmountDisplay: string;
  settledAmountDisplay?: string;
}

export interface ReimbursementViewModel {
  incurredBy: {
    classStatus: string;
    iconStatus: string;
    valid: boolean;
    selectedData?: Individual | Dependent;
  };
  expenseDate: {
    classStatus: string;
    iconStatus: string;
    valid: boolean;
    selectedData?: string;
  };
  expenseType: {
    classStatus: string;
    iconStatus: string;
    valid: boolean;
    selectedData?: ExpenseTypeViewModel;
  };
  expenseDetails: {
    classStatus: string;
    iconStatus: string;
    valid: boolean;
    selectedData?: {
      aFile: FileUploadContainer;
      expenseAmount: number;
      expenseDate: string;
      newProviderAdd: string;
      mileage: number;
      mileageRate: number;
      mileageReimbursement: number;
      description: string;
    };
  };
  reviewAndSubmit: {
    classStatus: string;
    iconStatus: string;
    valid: boolean;
  };
}

export interface ExpenseTypeViewModel {
  expenseType: string;
  serviceType: string;
}

export interface RequestTypeViewModel {
  expenseTypes: string[];
  serviceType: string;
}

export interface PieChartViewModel {
  amount: number;
  color?: string;
  dashArray?: string;
  dashOffset?: number;
  title: string;
}

export interface PaymentSourceAccountSearchQuery extends SearchQuery {
  includeInactive?: boolean;
}

export interface DateRange {
  startDate: string;
  endDate: string;
}

export interface OpenEndedPlanEnrollmentDateRange {
  startDate: dayjs.ConfigType;
  endDate: dayjs.ConfigType;
}

export class OpenEndedPlanEnrollmentRanges {
  public first: OpenEndedPlanEnrollmentDateRange;
  public second: OpenEndedPlanEnrollmentDateRange;
}

export interface BenefitPlanFilter {
  id: string;
  name: string;
  selected: boolean;
}
export interface BrandResources {
  BASIC: BrandResource;
  Crossroads: BrandResource;
  TASC: BrandResource;
  Unknown: BrandResource;
}

export interface BrandResource {
  /** The name used to refer to a general account. */
  generalAccountText: string;
  /** The name used to refer to a benefit account. */
  generalPlanText: string;
  /** The name used to refer to a general plan. */
  benefitAccountText: string;
  /** The name used to refer to a benefit plan. */
  benefitPlanText: string;
  /** The name used to refer to a benefit. */
  benefitText: string;
  /** The legal text to display when card is mentioned and an image of the card is shown */
  cardLegalText: string;
  /** The legal text to display when card is mentioned but image is not shown */
  cardLegalIssuerStatement: string;
  /** The name of the page containing card information */
  cardLink: string;
  /** The label for referring to the company card. Ex: 'TASC Card', 'BASIC Card' */
  companyCard: string;
  /** The full company name. Ex: 'Total Administrative Services Corporation' */
  companyFullName: string;
  /** The short version of the company name. Ex: 'TASC', 'BASIC' */
  companyName: string;
  /** The company name to use in the copyright message. Ex: BASIC Benefits, LLC */
  companyNameCopyright: string;
  /** The hours of operation for customer support. Ex: 'Monday - Friday, 8am-5pm CT' */
  contactHours: string;
  /** A brand-specific CSS class that can be applied to the HTML to help with CSS selection. Ex: 'brand-tasc', 'brand-basic' */
  brandCssClass: string;
  /** The file name for the favicon. Ex: favicon-tasc.ico, favicon-basic.ico */
  faviconFileName: string;
  /** Indicates whether to hide the benefit account summary page. */
  hideBenefitAccountSummary?: boolean;
  /** Indicates whether to hide the ability to set a card PIN. */
  hideCardPINSetup?: boolean;
  /** Indicates whether to hide the 'Report a lost or stolen card' section of the wallet page */
  hideCardLostOrStolen?: boolean;
  /** Indicates whether to hide the dashboard page. */
  hideDashboard?: boolean;
  /** Indicates whether to hide the dependent management page. */
  hideDependents?: boolean;
  /** Indicates whether to hide functionality related to benefit account enrollment. */
  hideEnrollment?: boolean;
  /** Indicates whether to hide the ability to request a new card. */
  hideNewCardRequest?: boolean;
  /** Indicates whether to hide support request and documents. */
  hideSupport?: boolean;
  /** Indicates whether to hide the transactions sub-navigation options. */
  hideTransactionsSubnav?: boolean;
  /** Indicates whether to hide the investments sub-navigation options. */
  hideWalletAvailableBalance?: boolean;
  /** Indicates whether to hide the wallet page if the user has not been issued a card. */
  hideWalletWithoutCard?: boolean;
  /** Indicates whether to hide the Alerts header links */
  hideAlertsLink?: boolean;
  /** Indicates whether to hide the Settings header links */
  hideSettingsLink?: boolean;
  /** Indicates whether to hide the Accounts Tab */
  hideAccountsTab?: boolean;
  /** Indicates whether to hide the Sitemap link */
  hideSitemapLink?: boolean;
  /** Indicates whether to hide the Copyright info from the footer */
  hideCopyright?: boolean;
  /** The file name containing the company logo. Ex: 'logo-tasc.png', 'logo-basic.png' */
  logoFileName: string;
  /** The label for the ID that uniquely identifies a participant. Ex: 'TASC ID', 'Member ID' */
  participantId: string;
  /** The company phone number. Ex: '800-422-4661' */
  phoneNumber: string;
  /** The name of this software product. Ex: Universal Benefit Account, Consumer Driven Accounts */
  productName: string;
  /** The file name for the first image shown on the RFR/Pay Provider confirmation screen. Ex: rfr-confirm-1-tasc.svg, rfr-confirm-1-basic.png */
  requestCompleteImage1FileName?: string;
  /** The file name for the third image shown on the RFR/Pay Provider confirmation screen. Ex: rfr-confirm-3-tasc.svg, rfr-confirm-3-basic.png */
  requestCompleteImage3FileName?: string;
  /** Indicates whether to show the payout wizard workflow. */
  showPayoutWorkflow?: boolean;
  /** The file name containing the Terms of Use. Ex: 'terms-tasc.html' */
  termsOfUseFileName: string;
  /** The file name containing the refund ACH transfer disclaimer Ex: 'ach-disclaimer.pdf' */
  achTransferDisclaimerFileName?: string;
  /** The file name containing the refund Mastercard send disclaimer Ex: 'mastercard-send-disclaimer.pdf' */
  mastercardSendDisclaimerFileName?: string;
  /** The file name containing the digital debit card disclaimer Ex: 'digital-card-disclaimer.pdf' */
  virtualCardDisclaimerFileName?: string;
  /** Path to the page that a user should be redirected to instead of '/login' if they visit an invalid route. Ex: '/payouts/home' */
  homePageOverride?: string;
}

export interface ContentfulContactUsModalData {
  title: string;
  index: number;
  specifier?: string;
  phone?: string;
  description?: string;
}

export enum ContentfulContentType {
  PuxContactUsModalContentTASC = 'puxContactUsModalContentTASC',
  PuxContactUsModalContentBASIC = 'puxContactUsModalContentBASIC',
}

export interface PaymentInfo {
  name: string;
  paymentType: PaymentSourceType | 'Bank Transfer';
  isGivingAccount?: boolean;
}

export interface OperationalBalanceInfoModel extends OperationalBalanceInfo {
  id: string;
}

export interface BenefitPlanQueryEffectiveDateGrouping {
  effectiveDate: string;
  benefitPlanIds: string[];
}

export interface DatedKeyData extends KeyData {
  date: Date;
}

export interface UIOption<T = string> {
  value: T;
  label: string;
}
