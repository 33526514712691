<app-modal size="small" #modal>
  <div class="modal-content">
    <div class="modal-body text-left">
      <h3>For assistance, please call us.</h3>
      <p *ngFor="let item of items">
        <b *ngIf="item.specifier">{{item.specifier}} </b><a *ngIf="item.phone" class="call-link" href="tel:+1-{{item.phone}}">{{item.phone}}</a>{{item.description}}
      </p>
    </div>

    <div class="modal-footer">
      <table summary="layout table" class="buttons">
        <caption class="sr-only">Buttons</caption>
        <tr>
          <td>
            <button tabindex="0" type="button" class="btn btn-primary" (click)="modal.close()">Close</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</app-modal>
